// This config is generated - please do not edit it in any way.
// Check scripts/generateConfig.js if you need to modify it in any way
interface Config {
  stage: string;
  cognitoConfig: {
    UserPoolId: string;
    ClientId: string;
  };
  appSync: {
    endpoint: string;
    apiKey: string;
  };
  userSnapApiKey: string;
  amplitudeApiKey: string;
  splitApiKey: string;
  dropboxClientId: string;
  smartyKey: string;
}
const config: Config = JSON.parse(
  '{"stage":"devArthur","cognitoConfig":{"UserPoolId":"us-east-1_0FX4A0Ltd","ClientId":"3dd5pq9ksmqh1q9v020sncnbog"},"appSync":{"endpoint":"https://mesh-professional-frontend-app-devarthur.mesh.id/graphql","apiKey":"da2-eszfbluebjezvms4jjvvflctb4"},"userSnapApiKey":"0f1873d5-b9f3-49dc-8586-3fe71c78c6a1","amplitudeApiKey":"6fa24fe4f01b49d1e34187ee202974bf","splitApiKey":"b34blo97ebpscob1opdp84eos7ltje2p9h8h","dropboxClientId":"184647dd5b7dea3501a20bf83fcb54f7","smartyKey":"192662170635639095"}'
);
export default config;
